@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");

:root {
  --body-bg: #fafafa;
  --bg-white: #fff;
  --bg-sidebar: #ffffff;
  --sidebar-text-color: #222222;
  --bg-secondary: #f3f3f3;
  --text-color: #000000e6;
  --border-color: #dcdfdf;
  --nav-shadow: #f2f2f2;
  --input-color: #444444;
  --placeholder-color: #636669;
  --link-color: #1d1f55;
  // --link-color: #3f51b5;
  --link-color-1: #1755de;
  --link-bg: #fff;
  --scroll-color: #1a0c02;
  --blue-text: #1d1f55;
  --card-bg: #fff;
  --font-icon-color: #1a0c02;
}

.dark-theme {
  --font-icon-color: #ffffff;
  --body-bg: #000000;
  --bg-white: #323232;
  --bg-sidebar: #323232;
  --sidebar-text-color: #ffffff;
  --bg-secondary: #1c1c1c;
  --text-color: #fff;
  --border-color: #fafafa;
  --nav-shadow: #1c1c1c;
  --input-color: #ffffff;
  --placeholder-color: #aaa9a9;
  --link-color: #fff;
  // --link-color: #ff0000;
  --link-color-1: #1a0c02;
  --link-bg: #333333;
  --scroll-color: #1a0c02;
  --blue-text: #1d1f55;
  --card-bg: #323232;
  --box-shadow: 0px 4px 26px rgba(66, 66, 66, 0.25);
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: var(--body-bg);
  color: #888;
  font-size: 15px;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  background-color: var(--body-bg);
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--link-color);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 54px;

  #content-wrapper {
    overflow-x: hidden;
    width: 100%;

    .container-fluid {
      padding: 30px 30px 30px 255px;
    }

    &.sidebar-toggled .container-fluid {
      padding: 30px 30px 30px 121px;
    }
  }
}

.single-channel-page {
  padding: 0px 0px 0px 225px;
}

.sidebar-toggled .single-channel-page {
  padding: 0px 0px 0px 90px;
}

.single-channel-page .container-fluid {
  padding: 30px 30px 30px !important;
}

body.fixed-nav #content-wrapper {
  margin-top: 56px;
  padding-left: 90px;

  &.sidebar-toggled {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  body.fixed-nav #content-wrapper {
    padding-left: 225px;

    &.sidebar-toggled {
      padding-left: 90px;
    }
  }
}

.carousel-login-card {
  padding: 11px 0;
}

.modal-content {
  background-color: var(--bg-secondary);
}

.modal-header {
  border-bottom: 1px solid #2e2e2e;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #2e2e2e;
}

.close {
  color: var(--text-color);

  &:hover {
    color: var(--text-color);
  }
}

.scroll-to-top {
  background: var(--bg-secondary);
  border-radius: 70px !important;
  bottom: 80px;
  color: #fff;
  height: 50px;
  line-height: 46px;
  position: fixed;
  right: 25px;
  text-align: center;
  width: 50px;

  &:focus {
    color: white;
  }

  &:hover {
    color: white;
    background: var(--bg-sidebar);
  }

  i {
    font-weight: 800;
  }
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.navbar-nav .form-inline .input-group {
  width: 100%;
}

.navbar-nav .nav-item {
  &.dropdown {
    .dropdown-toggle::after {
      width: 1rem;
      text-align: center;
      float: right;
      vertical-align: 0;
      border: 0;
      font-weight: 900;
      content: "\f105";
      font-family: "Font Awesome 5 Free";
    }

    &.show .dropdown-toggle::after {
      content: "\f107";
    }

    &.no-arrow .dropdown-toggle::after {
      display: none;
    }
  }

  .nav-link {
    &:focus {
      outline: none;
    }

    .badge {
      border: medium none !important;
      border-radius: 3px;
      font-size: 9px;
      font-weight: 700;
      height: 15px;
      line-height: 9px;
      min-width: 15px;
      position: absolute;
      text-align: center;
      top: 10px;
      right: 0;
    }
  }
}

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto;
  }
}

.sidebar {
  width: 225px !important;
  background: var(--bg-sidebar);
  min-height: 100%;
  z-index: 1030;
  padding: 2px 0;
  height: 100%;
  overflow-y: auto;
  // &:hover {
  //   overflow: auto;
  // }

  .nav-item {
    &:last-child {
      margin-bottom: 1rem;
    }

    .nav-link {
      font-size: 14px;
      padding: 12px 14px !important;
      font-weight: 600;
      width: 100%;
      display: block;

      // &:hover {
      //   background-color: rgba(255, 255, 255, 0.2);
      // }
    }

    &.active .nav-link {
      background-color: var(--bg-secondary);
    }

    .dropdown-menu {
      position: absolute !important;
      -webkit-transform: none !important;
      transform: none !important;
      left: calc(90px + 0rem) !important;
      margin: 0;

      &.dropup {
        bottom: 0;
        top: auto !important;
      }
    }

    &.dropdown .dropdown-toggle::after {
      display: none;
    }

    .nav-link {
      color: var(--sidebar-text-color);

      .ng-fa-icon {
        color: var(--sidebar-text-color);
      }

      // &:active,
      // &:focus,
      // &:hover {
      //   color: rgba(255, 255, 255, 0.75);

      //   .ng-fa-icon {
      //     color: rgba(255, 255, 255, 0.75);
      //   }
      // }

      span {
        display: inline;
        font-size: 13px;
        margin: 0 0 0 11px;
      }
    }
  }

  &.toggled {
    width: 0 !important;
    overflow: hidden;
  }

  &.fixed-top {
    top: 56px;
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .sidebar {
    height: 100%;
    position: fixed;
    width: 225px !important;
    // overflow: auto;
    padding-bottom: 20px;

    // &:hover {
    //   overflow: auto;
    // }

    .nav-item {
      .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 225px;

        span {
          display: inline;
          font-size: 13px;
          margin: 0 0 0 11px;
        }
      }

      .dropdown-menu {
        position: static !important;
        margin: 0 1rem;
        top: 0;
      }

      &.dropdown .dropdown-toggle::after {
        display: block;
      }
    }

    &.toggled {
      // overflow: visible;
      overflow: scroll;
      width: 90px !important;

      &:hover {
        overflow-x: auto;
      }

      .nav-item {
        &:last-child {
          margin-bottom: 1rem;
        }

        .nav-link {
          text-align: center;
          padding: 0.75rem 1rem;
          width: 90px;

          span {
            margin: 0;
            font-size: 0.65rem;
            display: block;
          }
        }

        .dropdown-menu {
          position: absolute !important;
          -webkit-transform: none !important;
          transform: none !important;
          left: calc(90px + 0.5rem) !important;
          margin: 0;

          &.dropup {
            bottom: 0;
            top: auto !important;
          }
        }

        &.dropdown .dropdown-toggle::after {
          display: none;
        }
      }
    }
  }
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -1.25rem;
  right: -1rem;
  opacity: 0.4;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.card-login {
  max-width: 25rem;
}

.card-register {
  max-width: 40rem;
}

.form-label-group {
  position: relative;

  > {
    input {
      padding: var(--input-padding-y) var(--input-padding-x);
      height: auto;
    }

    label {
      padding: var(--input-padding-y) var(--input-padding-x);
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      margin-bottom: 0;

      /* Override default `<label>` margin */
      line-height: 1.5;
      color: var(--input-color);
      border: 1px solid transparent;
      border-radius: 0.25rem;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;
    }
  }

  input {
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
      color: var(--placeholder-color);
    }

    &:not(:placeholder-shown) {
      padding-top: calc(
        var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
      );
      padding-bottom: calc(var(--input-padding-y) / 3);

      ~ label {
        padding-top: calc(var(--input-padding-y) / 3);
        padding-bottom: calc(var(--input-padding-y) / 3);
        font-size: 12px;
        color: var(--placeholder-color);
      }
    }
  }
}

footer.sticky-footer {
  background-color: var(--bg-white);
  padding: 15px;
  margin-left: 225px;

  .copyright {
    line-height: 1;
    font-size: 0.8rem;
  }
}

#content-wrapper.sidebar-toggled footer.sticky-footer {
  width: 100%;
}

@media (min-width: 768px) {
  #content-wrapper.sidebar-toggled footer.sticky-footer {
    width: calc(100% - 90px);
    margin-left: 90px;
  }
}

/* Nav */

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 99;
}

.osahan-nav {
  box-shadow: 0 0 10px var(--nav-shadow);
  background-color: var(--bg-white) !important;
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text-color);

  &:hover,
  &:focus {
    color: var(--text-color);
  }
}

.osahan-navbar-search {
  .form-control {
    padding: 2px 5px;
    max-width: 607px !important;
    width: 607px !important;
  }

  .btn {
    background: var(--border-color) none repeat scroll 0 0;
    border: medium none;
    font-size: 14px;
    padding: 0 18px;
    height: 100%;
  }
}

.osahan-right-navbar {
  margin: 0 7px 0 0;

  .nav-link {
    padding: 17px 13px !important;
    position: relative;
  }

  .osahan-right-navbar-user {
    .form-switch {
      .form-check-input {
        width: 2rem;
        padding: 8px;
      }
    }
  }
}
.form-control {
  background: #eceff0 none repeat scroll 0 0;
  border-color: #dcdfdf;
  border-radius: 2px;
  font-size: 13px;
}
.custom-select {
  background-color: #eceff0;
  border-color: #dcdfdf;
  border-radius: 2px;
  font-size: 13px;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}
.category-checkbox .custom-control {
  margin: 0 0 6px !important;
}
.right-action-link .fa {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  box-shadow: 0 0 6px #ececec;
  display: inline-block;
  height: 20px;
  line-height: 19px;
  margin: 0 0 0 3px;
  text-align: center;
  width: 20px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #eceff0 !important;
  color: #888888 !important;
  text-decoration: none;
}

.user-dropdown-link img {
  border-radius: 50px;
  height: 30px;
  margin: -9px 2px -6px 0;
  width: 30px;
}

.top-mobile-search {
  display: none;
  margin: -16px -15px 19px -18px;

  input {
    border-radius: 0;
    box-shadow: none !important;
  }

  .btn {
    border-radius: 0;
  }
}

.bottom-navbar-nav {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.mobile-menu {
  background: var(--bg-sidebar) none repeat scroll 0 0;
  bottom: 0;
  box-shadow: 0 0 11px var(--nav-shadow);
  left: 0;
  overflow-x: auto;
  position: fixed;
  right: 0;
  white-space: nowrap;
  z-index: 999;
  display: none;
  text-align: center;

  ul li {
    display: inline-block;
    list-style: outside none none;
    overflow: hidden;
    vertical-align: bottom;
  }
}

.bottom-nav-item span {
  color: var(--sidebar-text-color);
  display: list-item;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-nav-link {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  overflow: hidden;
  padding: 16px 6px 12px 6px;
  text-align: center;
  vertical-align: bottom;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 69px;

  .ng-fa-icon {
    color: var(--sidebar-text-color);
    font-size: 15px;
  }
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

/* Sidebar */

.sidebar {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.channel-sidebar-list {
  border-top: 1px solid var(--text-color);
  margin: 6px 0 0;
  padding: 7px 14px;

  h6 {
    color: var(--sidebar-text-color);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 9px;
  }

  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;

    li img {
      border-radius: 50px;
      height: 28px;
      margin: 0 14px 0 0;
      width: 28px;
    }
  }

  li div {
    color: var(--sidebar-text-color);
    display: inline-block;
    overflow: hidden;
    padding: 6px 0;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;

    .badge {
      float: right;
      margin: 7px 0 0;
      position: absolute;
      right: 20px;
    }
  }
}

.sidebar.toggled {
  .nav-item .nav-link span {
    margin: 0;
  }

  .channel-sidebar-list {
    h6 {
      font-size: 11px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    padding: 7px;
    text-align: center;

    li {
      img {
        display: list-item;
        margin: auto auto 4px;
      }

      a {
        font-size: 10px;
        font-weight: 500;
      }

      .badge {
        display: none;
      }
    }
  }
}

/* Customize Bootstrap */

.border-none {
  border: none;
}

.bg-primary,
// .btn-primary,
// .btn-outline-primary:hover,
.badge-primary {
  border-color: transparent !important;
  background: var(--link-color);

  /* Old browsers */
  // background: -moz-linear-gradient(
  //   -45deg,
  //   var(--link-color) 0%,
  //   var(--link-color-1) 100%
  // );

  /* FF3.6-15 */
  // background: -webkit-linear-gradient(
  //   -45deg,
  //   var(--link-color) 0%,
  //   var(--link-color-1) 100%
  // );

  /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(
  //   135deg,
  //   var(--link-color) 0%,
  //   var(--link-color-1) 100%
  // );

  // background: var(--bg-sidebar);
}

.btn-outline-primary {
  border-color: var(--link-color);
  color: var(--link-color);
}

.text-primary {
  color: var(--link-color) !important;
}

.progress-bar {
  background-color: var(--link-color);
}

.bg-success,
.btn-success,
.btn-outline-success:hover,
.badge-success {
  border-color: transparent !important;
  background: #198754;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #198754 1%, #3d7b5a 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #198754 1%, #3d7b5a 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #198754 1%, #3d7b5a 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#198754', endColorstr='#3d7b5a', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-success {
  border-color: #198754;
  color: #198754;
}

.text-success {
  color: #198754 !important;
}

.bg-warning,
.btn-warning,
.btn-outline-warning:hover,
.badge-warning {
  border-color: transparent !important;
  background: #ffc107;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ffc107 1%, #fffc07 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc107', endColorstr='#fffc07', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}

.text-warning {
  color: #ffc107 !important;
}

.bg-danger,
.btn-danger,
.btn-outline-danger:hover,
.badge-danger {
  border-color: transparent !important;
  background: #ff253a;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff253a', endColorstr='#ff8453', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-danger {
  border-color: #ff253a;
  color: #ff253a;
}

.text-danger {
  color: #ff253a !important;
}

.bg-secondary,
.btn-secondary,
.btn-outline-secondary:hover,
.badge-secondary {
  border-color: transparent !important;
  background: #9ba8aa;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #9ba8aa 0%, #92b4d2 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ba8aa', endColorstr='#92b4d2', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-secondary {
  border-color: #9ba8aa;
  color: #9ba8aa;
}

.text-secondary {
  color: #9ba8aa !important;
}

.bg-info,
.btn-info,
.btn-outline-info:hover,
.badge-info {
  border-color: transparent !important;
  background: #17a2b8;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #17a2b8 1%, #30def9 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#17a2b8', endColorstr='#30def9', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-info {
  border-color: #17a2b8;
  color: #17a2b8;
}

.text-info {
  color: #17a2b8 !important;
}

.bg-dark,
.btn-dark,
.btn-outline-dark:hover,
.badge-dark {
  border-color: transparent !important;
  background: #171b20;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #171b20 1%, #343a40 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #171b20 1%, #343a40 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #171b20 1%, #343a40 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#171b20', endColorstr='#343a40', GradientType=1);

  /* IE6-9 fallback on horizontal gradient */
}

.btn-outline-dark {
  border-color: #171b20;
  color: #171b20;
}

.text-dark {
  color: var(--text-color) !important;
}

.btn-light {
  border: medium none;
}

.adblock {
  background: var(--bg-secondary) none repeat scroll 0 0;
  border-radius: 2px;
  margin-bottom: 1rem;
  padding: 27px 10px;
  text-align: center;
  width: 100%;
}

.main-title > h6 {
  margin: 0px 0 16px;
}

.top-category .main-title > h6 {
  margin-bottom: 10px;
}

.right-action-link {
  .fa {
    background: var(--link-bg) none repeat scroll 0 0;
    border-radius: 50px;
    display: inline-block;
    height: 20px;
    line-height: 19px;
    margin: 0 0 0 3px;
    text-align: center;
    width: 20px;
  }

  &.dropdown-toggle::after {
    display: none;
  }
}

.form-control,
.form-select {
  background-color: var(--bg-secondary);
  border-color: var(--link-color);
  border-radius: 2px;
  font-size: 14px;
  color: var(--input-color);

  &:focus {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    color: var(--input-color);
  }

  &:disabled,
  &[readonly] {
    background-color: var(--body-bg);
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.form-check-label::before {
  border-radius: 2px;
}

.category-checkbox .custom-control {
  margin: 0 0 6px !important;
}

label {
  font-size: 12px;
  font-weight: 600;
  margin: 0 0 5px;
}

a {
  color: var(--link-color);
  text-decoration: none !important;
}

.btn {
  border-radius: 2px;
}

.btn-light {
  background: #f8f8f8 none repeat scroll 0 0;
  border-color: #d3d3d3;
  color: #828282;
}

.dropdown-header {
  color: var(--text-color);
  padding: 7px 13px;
}

.dropdown-menu {
  background: var(--bg-white) none repeat scroll 0 0;
  border-color: var(--bg-white);
  border-radius: 2px;
  margin: 0;

  .dropdown-item {
    color: var(--text-color);
    font-size: 12px;
    padding: 6px 14px !important;

    &:focus,
    &:hover {
      background-color: #a7a7a793;
    }
  }
}

.dropdown-divider {
  border-top: 1px solid var(--border-color);
}

a:hover,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}

.upload-video .ng-fa-icon {
  font-size: 200px;
}

.badge,
.alert {
  border-radius: 2px;
}

.pagination .page-item {
  .page-link {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: var(--text-color);
    line-height: 20px;
  }

  &.active .page-link {
    background: var(--link-color) none repeat scroll 0 0;
    border-radius: 2px;
    color: var(--text-color);
    height: 30px;
    margin: 0 7px;
    text-align: center;
    width: 30px;
  }

  &.disabled .page-link {
    opacity: 0.5;
  }
}

.modal-content {
  border: medium none;
  border-radius: 2px;
}

/* Category Slider */

.category-item {
  img {
    background: var(--bg-secondary) none repeat scroll 0 0;
    border: 2px solid var(--bg-secondary);
    border-radius: 50px;
    height: 60px;
    margin: 1px 0 8px;
    object-fit: scale-down;
    transform: scale(1);
    width: 60px;
  }

  &:hover img {
    transform: scale(1.09);
    transition-duration: 0.4s;
  }

  background: var(--bg-secondary) none repeat scroll 0 0;
  border-radius: 2px;
  margin: 6px 2px;
  padding: 24px 12px;
  text-align: center;
}

.top-category .category-item {
  padding: 20px 11px;
}

.category-item {
  h6 {
    font-size: 13px;
    margin: 5px 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 11px;
    font-weight: 500;
    margin: 0;
  }
}

/* Video Card */
.video-container {
  background: var(--card-bg);
}
.video-block .mb-3 {
  margin-bottom: 30px !important;
}

.video-card-image {
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.time {
  // background: #f5234a none repeat scroll 0 0;
  // border: 2px solid #fff;
  border-radius: 2px;
  bottom: 5px;
  // color: var(--text-color);
  font-size: 11px;
  font-weight: 500;
  opacity: 0.7;
  padding: 0 6px;
  position: absolute;
  right: 5px;
  background: #061726 none repeat scroll 0 0;
  border-radius: 10px;
}

.video-card:hover .time {
  opacity: unset;
}

.video-card-image img {
  width: 100%;
}

.play-icon {
  opacity: 0;
  position: absolute;

  .ng-fa-icon {
    color: #fff;
    bottom: 0;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
}

.video-card:hover .play-icon {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 0;
  color: var(--text-color);
  font-size: 35px;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition-duration: 0.4s;
}

.video-title {
  a {
    color: var(--text-color);
    font-weight: 600;
    text-decoration: none;
  }

  margin-bottom: 5px;
}

.video-page {
  font-size: 11px;
}

.video-view {
  color: #acacac;
  font-size: 12px;
  font-weight: 500;
  margin: 2px 0 0;
}

.video-card {
  background: var(--bg-secondary) none repeat scroll 0 0;
  border-radius: 2px;
  transition-duration: 0.4s;
}

.video-card-body {
  padding: 12px;
}

.video-card-list {
  .video-card-image {
    float: left;
    height: 88px;
    // height: 77px;
    margin: 0 12px 0 0;
    width: 158px;
    // width: 122px;

    @media (min-width: 768px) and (max-width: 1251px) {
      float: unset;
      margin-bottom: 6px;
      width: 100%;
    }
  }

  .video-card-body {
    padding: 0;

    .video-title {
      height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        font-size: 13px;
      }

      line-height: 17px;
    }

    .right-action {
      opacity: 0;
    }
  }

  background: transparent none repeat scroll 0 0;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 1rem;
  width: 100%;

  &:hover .video-card-body .right-action {
    opacity: unset;
  }
}
.single-video-comment-tabs .nav.nav-tabs {
  border-bottom: 2px solid #e7e9ee;
  margin: -15px -15px 15px;
  padding: 0 15px;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link {
  border-color: #b7b9be;
  border-radius: 2px !important;
  border-style: none none solid;
  border-width: 0 0 2px;
  color: #b7b9be;
  font-weight: 600;
  margin: 0 !important;
  padding: 16px 17px !important;
  text-transform: uppercase;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link.active,
.single-video-comment-tabs .nav.nav-tabs .nav-link:hover {
  border-color: #000;
  color: #000;
}
.single-video-comment-tabs .nav-tabs .nav-item {
  margin-bottom: -2px;
}
.reviews-members {
  border-bottom: 1px solid #dcdcdc;
  margin: 0 -15px;
  padding: 15px;
}
.reviews-members-header .text-black {
  color: var(--text-color) !important;
  // color: #000;
  font-weight: 600;
}
.reviews-members-header .text-gray {
  color: #7c7c7c;
  font-size: 13px;
  font-weight: 700;
  margin: 0 6px;
}
.reviews-members-header {
  margin-bottom: 13px;
}
.reviews-members-body {
  font-size: 17px;
  color: var(--text-color);
}

.reviews-members-footer {
  display: inline-block;
  width: 100%;
}
.total-like-user-main img {
  border: 2px solid #fff;
  box-shadow: 0 0 2px #ccc;
  height: 25px !important;
  margin: 0 0 0 -11px;
  width: 25px !important;
}
.reviews-members .media img {
  border-radius: 50px;
  height: 51px;
  width: 51px;
}
.total-like {
  border: 1px solid var(--link-color);
  border-radius: 3px;
  // color: #ff516b !important;
  font-size: 13px;
  padding: 4px 10px;
  vertical-align: sub;
}
.total-like:hover {
  background: var(--link-color) none repeat scroll 0 0;
  // background: #ff516b none repeat scroll 0 0;
  // border: 1px solid #ff516b;
  border-radius: 3px;
  color: var(--bg-sidebar) !important;
  font-size: 13px;
  padding: 4px 10px;
  vertical-align: sub;

  fa-icon svg path {
    fill: var(--bg-sidebar);
  }
}
.total-like-user-main.ml-2 {
  margin: 0 9px 0 17px !important;
}
.reviews-members:last-child {
  border: medium none;
  padding-bottom: 5px;
}
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

/* Channels Card */

.channels-card {
  background: var(--bg-secondary) none repeat scroll 0 0;
  border-radius: 10px;
  padding: 24px 12px;
  position: relative;
  text-align: center;
  transition-duration: 0.4s;

  // &::after {
  //   background-color: var(--sidebar-text-color);
  //   border-radius: 0 0 5px 6px;
  //   bottom: -4px;
  //   content: "";
  //   height: 7px;
  //   left: 15px;
  //   position: absolute;
  //   right: 15px;
  //   z-index: -1;
  //   opacity: 0.3;
  // }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
}

.channels-title a {
  color: var(--text-color);
  font-weight: 500;
  text-decoration: none;
}

.channels-view {
  color: #acacac;
}

.channels-card-image {
  .btn {
    font-size: 12px;
    padding: 3px 10px;

    strong {
      margin: 0 0 0 5px;
    }
  }

  img {
    background: var(--bg-secondary) none repeat scroll 0 0;
    border-radius: 50px;
    height: 80px;
    margin: 1px 0 8px;
    object-fit: scale-down;
    transform: scale(1);
    width: 80px;
  }
}

.channels-card-image-btn {
  margin: 3px 0 7px;
}

/* Login Pages */

.login-main-wrapper {
  background: #ff516b;

  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);

  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );

  /* IE6-9 fallback on horizontal gradient */
  height: calc(100vh - 0rem);
}

.full-height {
  height: calc(100vh - 0rem);
}

.login-main-left {
  margin: auto;
  max-width: 335px;
}

.login-main-right.bg-white {
  border-radius: 2px;
  margin: auto;
  max-width: 561px;
}

.carousel-login-card h5 {
  font-size: 18px;
}

.login-main-wrapper .form-control {
  background: transparent none repeat scroll 0 0;
}

.login-main-right .owl-theme .owl-controls .owl-page span {
  background: #dcdcdc;
  border-radius: 50px;
  height: 16px;
  margin: 0 2px;
  opacity: unset;
  width: 16px;
}

.btn-lg {
  font-size: 15px;
  padding: 12px 16px;
}

.login-main-right .owl-theme .owl-controls .owl-page {
  &.active span,
  span:hover {
    background: var(--link-color);

    /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      var(--link-color) 0%,
      var(--link-color-1) 100%
    );

    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      var(--link-color) 0%,
      var(--link-color-1) 100%
    );

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      var(--link-color) 0%,
      var(--link-color-1) 100%
    );
  }
}

/* Upload Details */

.imgplace {
  background-color: #8781bd;
  border-radius: 3px;
  height: 94px;
  width: 100%;
}

.osahan-title {
  color: var(--text-color);
  font-weight: 600;
}

.osahan-size {
  font-size: 11px;
}

.osahan-progress .progress {
  border-radius: 2px;
  height: 20px;
  margin: 14px 0;
}

.osahan-close {
  position: absolute;
  right: 18px;
  top: 0;
}

.osahan-desc {
  font-size: 12px;
  opacity: 0.7;
}

/* Watch History */

.video-close {
  color: var(--text-color) !important;
  font-size: 18px;
  position: absolute;
  right: 6px;
  z-index: 9;
}

.progress {
  background-color: var(--link-bg);
}

.history-video .progress {
  border-radius: 2px;
  font-size: 9px;
  font-weight: 700;
  height: 13px;
  margin: 12px 14px -4px 13px;
}

/* Single Channel */

.single-channel-image {
  position: relative;
}

.single-channel-nav {
  .navbar {
    padding: 0;
  }

  background: var(--bg-white) none repeat scroll 0 0 !important;
  padding: 0rem 30px 0;
}

.channel-brand {
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
}

.single-channel-nav {
  .nav-link {
    border-bottom: 2px solid transparent;
    margin: 0 12px;
    padding: 14px 0 !important;
  }

  .nav-item {
    &.active .nav-link,
    .nav-link:hover {
      border-color: var(--link-color);
      color: var(--link-color);
    }
  }

  .navbar-nav {
    margin: 0 0 0 40px;
  }
}

.channel-profile {
  bottom: 0;
  left: 0;
  padding: 1rem 30px;
  position: absolute;
  right: 0;
}

.single-channel-image .img-fluid {
  width: 100%;
}

.channel-profile-img {
  background: var(--text-color) none repeat scroll 0 0;
  border: 6px solid var(--text-color);
  border-radius: 50px;
  height: 90px;
  width: 90px;
}

.social {
  color: var(--text-color);
  float: right;
  margin: 62px 0 0;

  a {
    background: #000 none repeat scroll 0 0;
    border-radius: 2px;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
  }
}

/* Video Page */

.box {
  padding: 15px;
  background: var(--bg-secondary) none repeat scroll 0 0;
  border-radius: 2px;
  transition-duration: 0.4s;
}

.single-video-title h2 {
  font-size: 18px;

  a {
    color: var(--text-color);
  }
}

.single-video-author {
  img {
    border-radius: 50px;
    float: left;
    height: 38px;
    margin: 0 13px 0 0;
    width: 38px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.single-video-info-content h6 {
  font-size: 18px;
  font-weight: 500;
  color: #888888
}

.tags span a {
  background: var(--link-bg) none repeat scroll 0 0;
  border-radius: 2px;
  color: var(--text-color);
  display: inline-block;
  padding: 4px 9px;

  &:hover {
    background: #000 none repeat scroll 0 0;
  }
}

/* Footer */

.app img {
  background: #231f20 none repeat scroll 0 0;
  border-radius: 3px;
  height: 38px;
  margin: 5px 0 0;
  object-fit: scale-down;
  padding: 3px 4px;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .mobile-menu {
    display: block;
  }

  body {
    padding-bottom: 66px;
  }

  .top-mobile-search {
    display: block;
  }

  #wrapper #content-wrapper .container-fluid {
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 2rem !important;
    // padding-top: 1rem !important;
  }

  .sidebar .nav-item .nav-link {
    padding: 8px 14px !important;
  }

  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    padding-left: 15px;
  }

  .single-channel-page {
    padding: 0 0 0 0px !important;
  }

  .sidebar-toggled .single-channel-page {
    padding: 0 !important;
  }

  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }

  .single-video-author {
    .float-end {
      float: none !important;
      margin: 0 0 14px;
    }

    text-align: center;

    img {
      float: none;
      margin: 0 0 5px;
    }
  }

  .tags span a {
    margin: 0 0 3px;
  }

  .video-card-image img {
    width: 100%;
  }

  footer {
    padding: 15px 0 !important;
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;

    .ng-fa-icon {
      font-size: 12px;
    }
  }

  .navbar-brand img {
    vertical-align: top;
  }

  .single-channel-nav {
    padding: 15px;
  }

  .channel-profile {
    padding: 10px;
    position: relative;
    text-align: center;
  }

  .social {
    display: none;
  }

  .navbar-toggler {
    padding: 1px 3px;
  }

  .single-channel-nav {
    .navbar-nav,
    .nav-link {
      margin: 0;
    }
  }

  .imgplace {
    margin-bottom: 15px;
  }

  .osahan-title {
    padding: 0 25px 5px 0;
  }

  footer.sticky-footer {
    margin-left: 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .mobile-menu,
  .top-mobile-search {
    display: block;
  }

  body {
    padding-bottom: 66px;
  }

  #wrapper #content-wrapper .container-fluid {
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 2rem !important;
    // padding-top: 1rem !important;
  }

  .tags span a {
    margin: 0 0 3px;
  }

  .video-card-image img {
    width: 100%;
  }

  footer {
    padding: 15px 0 !important;
    text-align: left;

    .text-right {
      text-align: right !important;
    }
  }

  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;

    .ng-fa-icon {
      font-size: 12px;
    }
  }

  .navbar-brand img {
    vertical-align: top;
  }

  .single-channel-nav {
    padding: 15px;
  }

  .channel-profile {
    padding: 10px;
    position: relative;
    text-align: center;
  }

  .social {
    display: none;
  }

  .single-channel-nav {
    .navbar-nav,
    .nav-link {
      margin: 0;
    }
  }

  .imgplace {
    margin-bottom: 15px;
  }

  .osahan-title {
    padding: 0 25px 5px 0;
  }

  footer.sticky-footer {
    margin-left: 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #wrapper #content-wrapper .container-fluid {
    padding-bottom: 15px !important;
    padding-left: 243px;
    padding-right: 15px !important;
    padding-top: 2rem !important;
    // padding-top: 1rem !important;
  }

  .sidebar .nav-item .nav-link {
    padding: 8px 14px !important;
  }

  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    padding-left: 105px;
  }

  .single-channel-page {
    padding: 0 0 0 225px !important;
  }

  .sidebar-toggled .single-channel-page {
    padding: 0 0 0 90px !important;
  }

  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }

  .video-card-image img {
    width: 100%;
  }

  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;

    .ng-fa-icon {
      font-size: 12px;
    }
  }

  #sidebarToggle {
    margin: 0 9px 0 0;
  }

  .navbar-brand img {
    vertical-align: top;
  }

  .owl-theme .owl-controls .owl-buttons div {
    margin: 4px !important;
  }

  .container {
    max-width: 100%;
  }

  .single-channel-nav,
  .channel-profile {
    padding: 15px;
  }

  .social {
    display: none;
  }

  .navbar-toggler {
    padding: 1px 3px;
  }

  .single-channel-nav {
    .navbar-nav,
    .nav-link {
      margin: 0;
    }
  }

  .osahan-navbar-search {
    .form-control {
      width: auto !important;
    }

    margin-right: 15px !important;
  }

  .imgplace {
    margin-bottom: 15px;
  }

  .osahan-title {
    padding: 0 25px 5px 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  #wrapper #content-wrapper .container-fluid {
    padding-bottom: 15px !important;
    padding-left: 243px;
    padding-right: 15px !important;
    padding-top: 2rem !important;
    // padding-top: 1rem !important;
  }

  .sidebar .nav-item .nav-link {
    padding: 8px 14px !important;
  }

  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    padding-left: 105px;
  }

  .single-channel-page {
    padding: 0 0 0 225px !important;
  }

  .sidebar-toggled .single-channel-page {
    padding: 0 0 0 90px !important;
  }

  .single-channel-page .container-fluid {
    padding: 30px 15px 15px !important;
  }

  .video-card-image img {
    width: 100%;
  }

  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;

    .ng-fa-icon {
      font-size: 12px;
    }
  }

  #sidebarToggle {
    margin: 0 9px 0 0;
  }

  .navbar-brand img {
    vertical-align: top;
  }

  .owl-theme .owl-controls .owl-buttons div {
    margin: 4px !important;
  }

  .container {
    max-width: 100%;
  }

  .single-channel-nav {
    padding: 0 15px;
  }

  .channel-profile {
    padding: 15px;
  }

  .social {
    display: none;
  }

  .navbar-toggler {
    padding: 1px 3px;
  }

  .single-channel-nav {
    .navbar-nav {
      margin: 0 0 0 20px;
    }

    .nav-link {
      margin: 0 7px;
    }

    .form-control,
    .btn-outline-success {
      display: none;
    }
  }

  .osahan-navbar-search {
    .form-control {
      width: auto !important;
    }

    margin-right: 15px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

// Then add additional custom code here
@for $i from 1 through 100 {
  .w-#{$i*4}-px {
    width: $i * 4 + px;
    min-width: $i * 4 + px;
  }

  .h-#{$i*4}-px {
    height: $i * 4 + px;
    min-height: $i * 4 + px;
  }

  .font-#{$i*4}-px {
    font-size: $i * 4 + px;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.offcanvas {
  --bs-offcanvas-width: 225px !important;
}

.cursor {
  cursor: pointer;
}

.jw-state-idle .jw-controls {
  background: unset !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: var(--scroll-color) !important;
}

fa-icon svg path {
  fill: var(--font-icon-color);
  opacity: 0.9;
}

.video-description {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  em,
  div,
  strong,
  li {
    font-family: "Open Sans", sans-serif !important;
    font-size: 15px !important;
    line-height: 22px !important;
    margin: unset !important;
    letter-spacing: unset !important;
    background-color: unset !important;
    font-weight: 500 !important;
  }
  img,
  svg {
    display: none !important;
  }
}
